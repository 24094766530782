import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { toast } from 'react-toastify';

const getClassInfo = createAsyncThunk(
  'classDetails/getClassInfo',
  async ({ classId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.classInfo.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClassAppointments = createAsyncThunk(
  'classDetails/getClassAppointments',
  async ({ classId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.classAppointments.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateClassInfo = createAsyncThunk(
  'classDetails/updateClassInfo',
  async ({ classId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.classes.editClass.replace('{classId}', classId),
        data
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const checkClassSchedule = createAsyncThunk(
  'classDetails/checkClassSchedule',
  async (data) => {
    try {
      const res = await api.post(WEB_API_ROUTES.classes.schedule.check, data);

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const saveClassSchedule = createAsyncThunk(
  'classDetails/saveClassSchedule',
  async ({ classId, data }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.schedule.save.replace('{classId}', classId),
        data
      );

      toast.success('The Schedule data is saved');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClassScheduleList = createAsyncThunk(
  'classDetails/getClassScheduleList',
  async (classId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.schedule.list.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateClassScheduleInfo = createAsyncThunk(
  'classDetails/updateClassScheduleInfo',
  async ({ classRoomId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.classes.schedule.update.replace(
          '{classRoomId}',
          classRoomId
        ),
        data
      );
      toast.success('Changes made are saved');

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancersList = createAsyncThunk(
  'classDetails/getDancersList',
  async (classId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.dancersList.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClassDancers = createAsyncThunk(
  'classDetails/getClassDancers',
  async (classId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.classDancers.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addDancerToClass = createAsyncThunk(
  'classDetails/addDancerToClass',
  async ({ classId, dancers }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.classes.classDancers.replace('{classId}', classId),
        { dancers }
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const deleteClass = createAsyncThunk(
  'classDetails/deleteClass',
  async (classId) => {
    try {
      const res = await api.delete(
        WEB_API_ROUTES.classes.deleteClass.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getRecipients = createAsyncThunk(
  'classDetails/getRecipients',
  async (classId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.recipients.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendEmail = createAsyncThunk(
  'classDetails/sendEmail',
  async ({ classId, subject, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendEmail.replace('{classId}', classId),
        { subject, messageText }
      );

      toast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendSms = createAsyncThunk(
  'classDetails/sendSms',
  async ({ classId, messageText }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.sendSms.replace('{classId}', classId),
        { messageText }
      );

      toast.success('Message sent');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const creatingClassPricing = createAsyncThunk(
  'classDetails/creatingClassPricing',
  async ({ classId, data }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.classes.pricing.create.replace('{classId}', classId),
        data
      );

      toast.success('The Pricing Options was created');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getClassPricing = createAsyncThunk(
  'classDetails/getClassPricing',
  async ({ classId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.classes.pricing.get.replace('{classId}', classId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateClassPricing = createAsyncThunk(
  'classDetails/updateClassPricing',
  async ({ pricingId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.classes.pricing.update.replace('{pricingId}', pricingId),
        data
      );

      toast.success('The payment options were updated');
      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getClassInfo,
  getClassAppointments,
  updateClassInfo,
  checkClassSchedule,
  saveClassSchedule,
  getClassScheduleList,
  updateClassScheduleInfo,
  getDancersList,
  getClassDancers,
  addDancerToClass,
  deleteClass,
  getRecipients,
  sendEmail,
  sendSms,
  creatingClassPricing,
  getClassPricing,
  updateClassPricing,
};

export { thunks };
