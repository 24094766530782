import { createSlice } from '@reduxjs/toolkit';
import { selectors } from './selectors';
import { thunks } from './thunks';

const registrationForm = {
  signUp: {
    email: '',
    password: '',
  },
  phoneNumber: {
    phoneNumber: '',
  },
  companyInfo: {
    companyName: '',
    companyRole: { value: '', label: '' },
    companySize: { value: '', label: '' },
    companyType: { value: '', label: '' },
  },
  profileInfo: {
    profileName: '',
    avatar: '',
  },
  address: {
    addressLineFirst: '',
    addressLineSecond: '',
    city: '',
    state: '',
    zip: '',
    country: 'USA',
  },
  website: {
    url: '',
  },
};

const initialState = {
  ...registrationForm,
  step: 0,
  addEmailConfirmation: false,
  isPasswordRecoveryFlow: false,
  isLogin: true,
};

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    updateFormData: (state, action) => {
      const [step, data] = action.payload;
      state[step] = data;
    },
    goNext: (state) => {
      state.step += 1;
    },
    goPrevious: (state) => {
      state.step -= 1;
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setIsPasswordRecoveryFlow: (state, action) => {
      state.isPasswordRecoveryFlow = action.payload;
    },
    setAddEmailConfirmation: (state, action) => {
      state.addEmailConfirmation = action.payload;
    },
    setIsLogin: (state, action) => {
      state.isLogin = action.payload;
    },
    reset: () => initialState,
  },
});

const registration = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { registration };
export default slice.reducer;
