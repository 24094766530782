import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';
import { showToast } from 'utils/showToast';
import { createDancer } from 'store/createDancer';

const getDancerUpcomingAppointments = createAsyncThunk(
  'dancerDetails/getDancerUpcomingAppointments',
  async ({ dancerId }) => {
    try {
      const response = await api.get(
        WEB_API_ROUTES.dancers.upcomingAppointments + dancerId
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancerInfo = createAsyncThunk(
  'dancerDetails/getDancerInfo',
  async ({ dancerId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.dancerInfo.replace('{dancerId}', dancerId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateDancerInfo = createAsyncThunk(
  'dancerDetails/updateDancerInfo',
  async ({ dancerId, data }) => {
    try {
      const res = await api.post(
        WEB_API_ROUTES.dancers.editDancer.replace('{dancerId}', dancerId),
        data
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const createGuardian = createAsyncThunk(
  'dancerDetails/createGuardian',
  async ({ data, _id }, { dispatch }) => {
    try {
      const { data: guardianData } = await api.post(
        WEB_API_ROUTES.dancers.createRelative,
        data
      );

      const newRelativeId = {
        value: guardianData.data.relative.id,
        label: `${guardianData.data.relative.first_name} ${guardianData.data.relative.last_name}`,
      };

      dispatch(createDancer.actions.addRelative(newRelativeId));

      showToast.success('Relative successfully added to the system');

      return {
        relative: { ...guardianData.data.relative, id: newRelativeId },
        _id,
      };
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancerEvaluationInfo = createAsyncThunk(
  'dancerDetails/getDancerEvaluationInfo',
  async ({ dancerId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.evaluationInfo.replace('{dancerId}', dancerId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const updateDancerEvaluation = createAsyncThunk(
  'dancerDetails/updateDancerEvaluation',
  async ({ dancerId, data }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.dancers.evaluationInfo.replace('{dancerId}', dancerId),
        {
          evaluations: data,
        }
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPerformances = createAsyncThunk(
  'dancerDetails/getPerformances',
  async ({ dancerId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.performance.replace('{dancerId}', dancerId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getNotifications = createAsyncThunk(
  'dancerDetails/getNotifications',
  async (dancerId) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.dancerNotifications.replace(
          '{dancerId}',
          dancerId
        )
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getDancerClasses = createAsyncThunk(
  'dancerDetails/getDancerClasses',
  async ({ dancerId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.dancerClasses.replace('{dancerId}', dancerId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getAvailableClasses = createAsyncThunk(
  'dancerDetails/getAvailableClasses',
  async ({ dancerId }) => {
    try {
      const res = await api.get(
        WEB_API_ROUTES.dancers.availableClasses.replace('{dancerId}', dancerId)
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const addDancerToClasses = createAsyncThunk(
  'dancerDetails/addDancerToClass',
  async ({ dancerId, classes }) => {
    try {
      const res = await api.put(
        WEB_API_ROUTES.dancers.dancerClasses.replace('{dancerId}', dancerId),
        { classes }
      );

      return res.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getDancerUpcomingAppointments,
  getDancerInfo,
  createGuardian,
  updateDancerInfo,
  getDancerEvaluationInfo,
  updateDancerEvaluation,
  getPerformances,
  getNotifications,
  getDancerClasses,
  getAvailableClasses,
  addDancerToClasses,
};

export { thunks };
