export const APP_ROUTES = {
  notFound: 'not-found',

  auth: {
    index: '/auth/*',
    logIn: 'login',
    createPassword: 'create-password',
  },

  user: {
    index: '/*',
    dashboard: '/',
    scheduler: '/scheduler',

    dancers: {
      index: '/dancers',
      create: '/dancers/create',
      details: '/dancers/:id/details',
      notifications: 'dancers/:id/notifications',
    },
    parents: {
      index: '/parents',
      create: '/parents/create',
      details: '/parents/:id/details',
      notifications: '/parents/:id/notifications',
    },
    classes: {
      index: '/classes',
      create: '/classes/create',
      details: '/classes/:id/details',
      dancers: '/classes/:id/dancers',
      schedule: '/classes/:id/schedule',
      pricing: '/classes/:id/pricing',
    },
  },
};
