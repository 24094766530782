import styled, { css } from 'styled-components';

const Button = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ height }) => `${height}px`};
  padding: 0 18px;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  border-color: transparent;
  overflow: hidden;
  transition: 0.15s linear;
  cursor: pointer;

  ${({ variant, variantColor, disabled, theme }) => {
    switch (variant) {
      case 'outline':
        return css`
          color: ${disabled ? theme.gray100 : theme.gray950};
          background-color: ${theme.gray50};

          &:hover {
            background-color: ${disabled ? theme.gray50 : theme.gray75};
          }

          &:active {
            background-color: ${disabled ? theme.gray50 : theme.gray90};
          }
        `;
      case 'outlineBordered':
        return css`
          border: 1px solid ${theme.gray100};
          color: ${theme.gray950};
          font-weight: 500;
          border-radius: 8px;
          background-color: transparent;
          padding: 9px 25px;
          opacity: ${disabled ? 0.3 : 1};

          &:hover {
            background-color: ${disabled ? 'transparent' : theme.gray75};
          }

          &:active {
            background-color: ${disabled ? 'transparent' : theme.gray90};
          }
        `;
      case 'small':
        return css`
          border-radius: 8px;
          color: ${theme.gray25};
          background-color: ${theme.brand500};
          opacity: ${disabled ? 0.3 : 1};
          padding: 8px 16px;

          &:hover {
            background-color: ${theme.brand600};
          }

          &:active {
            background-color: ${theme.brand700};
          }
        `;
      case 'smallOutlineBordered':
        return css`
          border: 1px solid ${theme.brand500};
          color: ${theme.brand500};
          border-radius: 8px;
          background-color: transparent;
          padding: 9px 29px;
          font-weight: 400;

          /* &:hover {
            background-color: ${theme.brand500};
          }

          &:active {
            background-color: ${theme.brand500};
          } */
          ${({ disabled }) =>
            disabled &&
            css`
              cursor: auto;

              color: #b3a7ce;
              border-color: #b3a7ce;

              svg path {
                fill: #b3a7ce;
              }
            `}
        `;
      case 'text':
        return css`
          font-size: 16px;
          font-weight: 500;
          color: ${disabled ? theme.gray100 : theme.gray950};
          background-color: ${theme.white};
        `;
      default:
        return css`
          font-size: 16px;
          font-weight: 500;
          color: ${theme.gray25};
          background-color: ${variantColor || theme.brand500};
          opacity: ${disabled ? 0.3 : 1};
          ${({ fullWidth }) => !fullWidth && 'padding: 11px 36px;'};

          &:hover {
            background-color: ${variantColor || theme.brand600};
          }

          &:active {
            background-color: ${theme.brand700};
          }
        `;
    }
  }}

  ${({ css }) => css}
`;

const ButtonChildren = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PrependIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      & > svg {
        fill: ${theme.gainsboro};
      }
    `}
`;

const LoadingStyled = styled.img`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotate 2s linear infinite;
`;

export { Button, ButtonChildren, PrependIcon, LoadingStyled };
