const guardians = (state) => {
  const pending = state.dancerDetails.relationships.filter(
    (g) => g.status === 'pending'
  );
  const saved = state.dancerDetails.relationships.filter(
    (g) => g.status === 'saved'
  );

  return { pending, saved };
};

const selectors = {
  guardians,
  dancerInfo: (state) => state.dancerDetails.dancerInfo,
  relationships: (state) => state.dancerDetails.relationships,
  notifications: (state) => state.dancerDetails.notifications,
  createGuardianStatus: (state) => state.dancerDetails.createGuardianStatus,
  dancerEvaluation: (state) => state.dancerDetails.dancerEvaluation,
  dancerUpcomingAppointments: (state) =>
    state.dancerDetails.dancerUpcomingAppointments,
  loadingDancerInfoStatus: (state) =>
    state.dancerDetails.loadingDancerInfoStatus,
  getEvaluationStatus: (state) => state.dancerDetails.getEvaluationStatus,
  performance: (state) => state.dancerDetails.performance,
  loadingNotificationsStatus: (state) =>
    state.dancerDetails.loadingNotificationsStatus,
  loadingClassesStatus: (state) => state.dancerDetails.loadingClassesStatus,
  dancerClasses: (state) => state.dancerDetails.dancerClasses,
  availableClasses: (state) => state.dancerDetails.availableClasses,
  loadingAvailableClassesStatus: (state) =>
    state.dancerDetails.loadingAvailableClassesStatus,
};

export { selectors };
