import { createSlice } from '@reduxjs/toolkit';

import { status } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  isSidebarOpen: false,
  teachers: [],
  locations: [],
  states: [],
  cities: [],
  countries: [],
  fetchingStatesStatus: status.IDLE,
  fetchingCitiesStatus: status.IDLE,
};

const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSidebarOpen: (state, { payload }) => {
      state.isSidebarOpen = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getStates.fulfilled, (state, { payload }) => {
        state.states = payload.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        state.fetchingStatesStatus = status.SUCCESS;
      })

      .addCase(thunks.getCities.fulfilled, (state, { payload }) => {
        state.cities = payload.data;
        state.fetchingCitiesStatus = status.SUCCESS;
      })

      .addCase(thunks.getLocations.fulfilled, (state, { payload }) => {
        state.locations = payload.data;
      })

      .addCase(thunks.getTeachers.fulfilled, (state, { payload }) => {
        state.teachers = payload.data;
      });
  },
});

const general = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { general };
export default slice.reducer;
